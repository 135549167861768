<template>
  <el-row>
    <el-col :span="10" class="dis-sta-cen">
      <div class="divLeft" v-if="clearForm">
        <el-cascader
          ref="cascader"
          clearable
          collapse-tags
          separator=">"
          filterable="true"
          placeholder="请选择资质分类"
          style="width: 100%;"
          v-model="cascaderValue"
          size="medium"
          :options="cascaderOptions"
          :props="cascaderProps"
          @change="handleChange"></el-cascader>
      </div>
    </el-col>
    <el-col :span="5"><div class="divLeft"></div></el-col>
    <el-col :span="7">
      <div class="divLeft" v-show="multiSelect">
        <el-radio-group v-model="radio" size="small" text-color="#666666" fill="#E1E2E6">
          <el-row :gutter="30">
            <el-col :span="10"><el-radio-button label="0">符合任意资质</el-radio-button></el-col>
            <el-col :span="10"><el-radio-button label="1">符合全部资质</el-radio-button></el-col>
          </el-row>
        </el-radio-group>
      </div>
    </el-col>
    <el-col :span="2">
      <el-button class="scaling" type="text" @click="switchClick">
        <p v-if="!multiSelect" >多选<i class="iconfont">&#xe612;</i></p>
        <p v-if="multiSelect">取消多选<i class="iconfont">&#xe613;</i></p>
      </el-button>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'cascader-switch',
  props: {
    cascaderOptions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      // thisDefaultShowNum: 0
      radio: '0',
      multiSelect: false,
      cascaderValue: [],
      cascaderProps: { multiple: false, expandTrigger: 'hover' },
      item: [],
      clearForm: true
    }
  },
  watch: {
    radio (newVal) {
      const vm = this
      vm.returnData()
    }
  },
  methods: {
    // 清理数据
    closeDate () {
      const vm = this
      vm.cascaderValue = []
      vm.item = []
      // vm.clearForm = false
      // setTimeout(function () {
      //   vm.clearForm = true
      // }, 50)
    },
    // 点击更多或者收起
    switchClick () {
      const vm = this
      vm.clearForm = false
      setTimeout(function () {
        vm.clearForm = true
        vm.multiSelect = !vm.multiSelect
        if (!vm.multiSelect) {
          vm.cascaderProps.multiple = false
          const obj = null
          vm.$emit('handleChange', obj)
        } else if (vm.multiSelect) {
          const obj = null
          vm.$emit('handleChange', obj)
          vm.cascaderProps.multiple = true
        }
        vm.cascaderValue = []
        vm.radio = '0'
        vm.item = []
      }, 50)
    },
    handleChange (item) {
      const vm = this
      vm.item = JSON.parse(JSON.stringify(item))
      vm.returnData()
    },
    // 处理返回数据
    returnData () {
      const vm = this
      let arr = []
      if (vm.multiSelect) { // 是多选
        arr = JSON.parse(JSON.stringify(vm.item))
      } else {
        arr = [vm.item]
      }
      const item = {
        multiSelect: vm.multiSelect,
        radio: vm.radio, // 0:符合任意资质 1：符合全部资质
        item: arr // 选中的数据 固定二维数组
      }
      if (arr.length > 0 && arr[0].length > 0) {
        vm.$emit('handleChange', item)
      } else {
        const obj = null
        vm.$emit('handleChange', obj)
      }
    }
  }
}
</script>

<style scoped>
.divLeft {
  width: 100%;
  margin: 4px 0px 0px 0px;
}
.scaling p{
  float: right;
  font-size: 14px;
  color: #666666;
}
.el-cascader-menu {
  height: 300px;
}
/deep/ .el-cascader-panel {
  height: 215px;
}
</style>
