<template>
  <div style="min-height: 500px;position: relative;">
    <search-nav :active="1"></search-nav>
    <!-- <el-affix :offset="0"> -->
      <el-row>
        <div class="container">
          <el-row class="input-search">
            <el-col :span="13">
              <el-autocomplete
                style="width: 100%"
                clearable
                class="inline-input"
                v-model="conditions.keyword"
                :fetch-suggestions="querySearch"
                placeholder="请输入资质名称关键词"
                :trigger-on-focus="false"
                @select="handleSelect"
                size="medium"
                input-style="border-bottom-right-radius:0px;border-top-right-radius:0px;height:50px"
              >
              </el-autocomplete>
            </el-col>
            <el-col :span="2">
              <el-button type="primary" style="background: #FC7A1E;border-color: #FC7A1E;" @click="handleSearchList" class="search-btn-search"  size="medium">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </el-row>
    <!-- </el-affix> -->
    <div class="container">
      <!-- 条件 -->
      <el-row style="margin-top: 30px;">
        <el-col :span="24">
          <div class="condition-content">
            <div class="dis-bet-cen condition-title">
              <span class="screening-conditions-title">筛选条件</span>
              <link-to-advanced></link-to-advanced>
            </div>
            <div style="padding: 10px 0 20px 0;">
              <el-row v-if="tags.length > 0" class="dis-cen-cen">
                <el-col :span="2">
                  <div class="grid-content bg-purple" style="font-size: 14px;color: #5D6FE9;height: 40px;line-height: 40px;">已选条件:</div>
                </el-col>
                <el-col :span="20">
                  <div class="grid-content bg-purple dis-sta-cen">
                    <el-tag style="margin-right: 10px" v-for="(tag, index) in tags" :key="tag.name" closable :type="tag.type" @close="closeTag(tag, index)">
                      {{tag.name}}
                    </el-tag>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div @click="closeAllTag" class="grid-content bg-purple font-choose-text">清除全部</div>
                </el-col>
              </el-row>
              <el-row v-if="!conditions.provinceModel.name" class="dis-cen-sta">
                <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #999999;height: 40px;line-height: 40px;">省份地区:</div></el-col>
                <el-col :span="22">
                  <div class="grid-content bg-purple">
                    <arrangement-radio :valueArr="arrangementRadioValueArr" :defaultShowNum="arrangementRadioDefaultShowNum" @handleChange="arrangementRadioChange"></arrangement-radio>
                  </div>
                </el-col>
              </el-row>
              <el-row v-if="!conditions.cityModel.name && conditions.provinceModel.name && conditions.provinceModel.name != '北京市' && conditions.provinceModel.name != '天津市' && conditions.provinceModel.name != '上海市' && conditions.provinceModel.name != '重庆市'" class="dis-cen-cen">
                <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #999999;">城市：</div></el-col>
                <el-col :span="22">
                  <div class="grid-content bg-purple">
                    <arrangement-radio :valueArr="arrangementRadioCityValueArr" :defaultShowNum="arrangementRadioCityDefaultShowNum" @handleChange="arrangementRadioCityChange"></arrangement-radio>
                  </div>
                </el-col>
              </el-row>
              <el-row v-if="conditions.multiSelect || conditions.multiSelect == null" class="dis-cen-cen">
                <el-col :span="2"><div class="grid-content bg-purple" style="font-size: 14px;color: #999999;">资质类别:</div></el-col>
                <el-col :span="22">
                  <div v-if="clearForm" class="grid-content bg-purple">
                    <cascader-switch ref="cascaderSwitchRef" :cascaderOptions="cascaderOptions" @handleChange="cascaderSwitchChange"></cascader-switch>
                  </div>
                </el-col>
              </el-row>
              <el-row class="dis-cen-cen" style="margin-top: 10px;">
                <el-col :span="2">
                  <div class="grid-content bg-purple" >
                    <el-button type="primary" @click="queryList" size="medium">查询</el-button>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- list -->
      <el-row style="margin-top: 30px;">
        <el-col :span="24">
          <div class="condition-content">
            <div class="dis-bet-cen condition-title">
              <span class="screening-conditions-title">共查询到 <span class="font-color-red">{{page.total}}</span> 条符合条件的资质</span>
              <span class="advanced-query-title">
                <el-select v-model="value" @change="handleSelsect" placeholder="请选择">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
              </span>
            </div>
            <div class="condition-content-list">
              <!-- 资质 -->
              <block v-show="!fuzzyDataShow" v-for="(item, index) of list" :key="index">
                <el-row class="list-padding list-hover">
                  <el-col :span="24">
                    <div class="condition-content-list-name dis-bet-cen">
                      <span v-html="item.subQualificationName"></span>
                      <span v-if="item.recordGrade == '4'">
                        <el-tooltip class="item" effect="dark" content="用户提供了完整的资料数据，但无法在公开网站上核验" placement="bottom-end">
                          <span  class="focus-enterprises-tag-yellow">未核验</span>
                        </el-tooltip>
                      </span>
                    </div>
                  </el-col>
                  <el-col :span="24">
                    <el-row :gutter="20" class="condition-content-list-title">
                      <el-col :span="7">资质证书编号：<span class="font-color-gray">{{item.credentialNumber || '-'}}</span></el-col>
                      <el-col :span="7">发证机关：<span class="font-color-gray">{{item.issuedBy || '-'}}</span></el-col>
                      <el-col :span="5">发证日期：<span class="font-color-gray">{{item.issueDate || '-'}}</span></el-col>
                      <el-col :span="5">有效日至：<span class="font-color-gray">{{item.closingDate || '-'}}</span></el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="24">
                    <el-row :gutter="20" class="condition-content-list-title">
                      <el-col :span="10">企业名称：<span @click="companyNameClick(item.companyId)" class="font-color-gray" style="cursor:pointer;">{{item.companyName || '-'}}</span></el-col>
                    </el-row>
                  </el-col>
                </el-row>
                <el-divider v-if="(index + 1) != list.length"></el-divider>
              </block>
              <el-empty v-if="list.length === 0"  :image-size="200"></el-empty>
              <gaussian-blur @loginEmit="loginEmit" pageName="company-credential" v-show="fuzzyDataShow"></gaussian-blur>
            </div>
          </div>
        </el-col>
      </el-row>
      <!--分页组件-->
      <div class="dis-end-sta" style="margin: 30px 0">
        <el-pagination
          :hide-on-single-page="true"
          :pager-count="page.pagerCount"
          @current-change="currentChange"
          v-model:currentPage="page.pageNum"
          background
          layout="prev, pager, next"
          :total="page.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import ArrangementRadio from '@/components/query-conditions/arrangement-radio'
import SearchNav from '@/components/search-nav/search-nav'
import GaussianBlur from '@/components/gaussian-blur/gaussian-blur'
import LinkToAdvanced from '@/components/link-to-advanced/link-to-advanced'
import CascaderSwitch from '@/components/query-conditions/cascader-switch'
import provinceData from '@/assets/pca-code.js'
import { forwardBG, oldForwardBG } from '@/api/forward.js'
import { getRelevancy } from '@/api/query.js'
import { mapGetters } from 'vuex'
export default {
  name: 'company-credential',
  components: { SearchNav, ArrangementRadio, CascaderSwitch, LinkToAdvanced, GaussianBlur },
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'topNav')
    }
    return {
      loginEmit
    }
  },
  data () {
    return {
      provinceData: provinceData,
      conditions: {
        keyword: this.$route.query.searchVal ? this.$route.query.searchVal : '', // 查询的关键字
        provinceModel: {}, // 已经选择的省份
        cityModel: {}, // 已经选择的市
        relevancyIdArr: [], // 资质分类多级数组
        multiSelect: null,
        radio: null,
        credentialType: ''
      },
      queryConditions: {
        keyword: '', // 查询的关键字
        provinceModel: {}, // 已经选择的省份
        cityModel: {}, // 已经选择的市
        relevancyIdArr: [], // 资质分类多级数组
        multiSelect: null,
        radio: null,
        orderType: '',
        credentialType: ''
      },
      tags: [],
      clearForm: true,
      isfocus: 'blur',
      arrangementRadioValueArr: [], // 平铺单选的组件数据
      arrangementRadioCityValueArr: [],
      arrangementRadioDefaultShowNum: 12, // 平铺单选的默认显示数量
      url: require('../../assets/index/g.png'),
      page: { // 分页使用
        total: 0, // 总条数
        pagerCount: 7,
        pageNum: 1,
        pageSize: 10
      },
      options: [
        {
          value: '0',
          label: '默认排序',
          orderType: ''
        }, {
          value: '1',
          label: '发证时间升序',
          orderType: 'asc'
        }, {
          value: '2',
          label: '发证时间降序',
          orderType: 'desc'
        }
      ],
      value: '0',
      cascaderOptions: [], // 需要的格式参考饿了么UI 的
      totalCascaderList: [], // 所有条件
      list: [],
      oldKeyword: '',
      returnLimit: false, // 返回数据是否受限
      fuzzyDataShow: false, // 模糊图片是否显示
      fuzzyData: require('@/assets/images/fuzzy_data.png')
    }
  },
  created () {
    const vm = this
    vm.initArrangementRadioValueArr()
    vm.handleSearchList()
  },
  computed: {
    // vuex获取值
    // this.$store.user.idToken = obj
    ...mapGetters([
      'idToken',
      'vipData'
    ]),
    vipLevelChange: function () {
      const vm = this
      if (vm.vipData && JSON.parse(vm.vipData)) {
        return JSON.parse(vm.vipData).vipLevel
      } else {
        return null
      }
    }
  },
  watch: {
    vipLevelChange: function (newValue, oldValue) {
      const vm = this
      vm.initArrangementRadioValueArr()
      vm.handleSearchList()
    }
  },
  methods: {
    // 获取所有资质和省份
    initArrangementRadioValueArr () {
      const vm = this
      vm.arrangementRadioValueArr = vm.provinceData.pcaData
      getRelevancy().then(result => {
        if (result.code === 0) {
          const res = result.data
          vm.totalCascaderList = res
          vm.getRelevancyList(res, '0', function (a) {
            vm.cascaderOptions = a
          })
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getRelevancyList: function (data, parentId, callback) {
      const arr = []
      const vm = this
      data.forEach((item, index) => {
        let obj = {}
        if (item.parentId === parentId) {
          obj = {
            value: item.id,
            label: item.name,
            parentId: item.parentId
          }
          obj.children = vm.getRelevancyListChild(data, item, item.id)
          arr.push(obj)
        }
      })
      callback(arr)
    },
    getRelevancyListChild: function (arr, parentItem, parentId) {
      const vm = this
      const arrs = []
      arr.forEach((item, index) => {
        let obj = {}
        if (item.parentId === parentId) {
          if (vm.getRelevancyListChild(arr, item, item.id).length > 0) {
            obj = {
              value: item.id,
              label: item.name,
              parentId: item.parentId,
              children: vm.getRelevancyListChild(arr, item, item.id)
            }
          } else {
            obj = {
              value: item.id,
              label: item.name,
              parentId: item.parentId
            }
          }
          arrs.push(obj)
        }
      })
      return arrs
    },
    // 省市组件选择完了的回调
    arrangementRadioChange (item) {
      const vm = this
      vm.arrangementRadioCityValueArr = item.children
      vm.conditions.provinceModel = item
      vm.tags.push({
        name: '省份地区：' + item.name,
        value: 'provinceModel'
      })
    },
    // 省市组件选择完了的回调 市
    arrangementRadioCityChange (item) {
      const vm = this
      vm.conditions.cityModel = item
      vm.tags.push({
        name: '城市：' + item.name,
        value: 'cityModel'
      })
    },
    // 关闭Tag触发
    closeTag (item, index) {
      const vm = this
      vm.tags.splice(index, 1)
      vm.conditions[item.value] = {}
      if (item.value === 'provinceModel') {
        vm.conditions.cityModel = {}
        for (let i = 0; i < vm.tags.length; i++) {
          if (vm.tags[i].value === 'cityModel') {
            return vm.closeTag(vm.tags[i], i)
          }
        }
      }
      if (item.value === 'relevancyIdArr') {
        vm.conditions[item.value] = item.default
        vm.conditions.multiSelect = null
        vm.conditions.radio = null
        vm.conditions.credentialType = ''
        if (vm.$refs.cascaderSwitchRef) vm.$refs.cascaderSwitchRef.closeDate()
      }
    },
    // 清除全部Tag
    closeAllTag () {
      const vm = this
      vm.tags = []
      vm.conditions.provinceModel = {}
      vm.conditions.cityModel = {}
      vm.conditions.relevancyIdArr = {}
      vm.conditions.multiSelect = null
      vm.conditions.radio = null
      vm.conditions.credentialType = ''
    },
    // 级联切换组件选择完了的回调
    cascaderSwitchChange (item) {
      const vm = this
      if (item) {
        if (item.multiSelect === false) {
          const data = item.item[0]
          vm.conditions.relevancyIdArr = JSON.parse(JSON.stringify(data))
          vm.conditions.multiSelect = item.multiSelect
          vm.conditions.radio = item.radio
          const treeToNameResult = vm.treeToName(item.item)
          vm.conditions.credentialType = treeToNameResult
          const relevancyNameArr = vm.treeToLabel(vm.cascaderOptions, data)
          vm.tags.push({
            name: '资质类别：' + relevancyNameArr,
            value: 'relevancyIdArr',
            default: []
          })
        } else {
          const data = item.item
          vm.conditions.relevancyIdArr = JSON.parse(JSON.stringify(item.item))
          vm.conditions.multiSelect = item.multiSelect
          vm.conditions.radio = item.radio
          const treeToNameResult = vm.treeToName(item.item)
          vm.conditions.credentialType = treeToNameResult
          if (JSON.stringify(vm.tags).indexOf('relevancyIdArr') !== -1) {
            vm.tags.forEach((item, index) => {
              if (item.value === 'relevancyIdArr') {
                vm.tags[index].name = '资质类别：' + data.length + '个'
              }
            })
          } else {
            vm.tags.push({
              name: '资质类别：' + data.length + '个',
              value: 'relevancyIdArr',
              default: []
            })
          }
        }
      } else {
        vm.tags.forEach((item, index) => {
          if (item.value === 'relevancyIdArr') {
            vm.tags.splice(index, 1)
          }
        })
        vm.conditions.relevancyIdArr = {}
        vm.conditions.multiSelect = null
        vm.conditions.radio = null
        vm.conditions.credentialType = ''
      }
    },
    // 翻页时触发
    currentChange (cuttentPage) {
      const vm = this
      if (vm.idToken) {
        if (vm.returnLimit) { // 已经返回受限了 就不继续查询了
          vm.page.pageNum = -Math.ceil(Math.random() * 1000)
          vm.fuzzyDataShow = true
        } else {
          document.documentElement.scrollTop = 0
          vm.page.pageNum = cuttentPage
          vm.getList()
        }
      } else {
        vm.page.pageNum = -Math.ceil(Math.random() * 1000)
        vm.loginEmit()
      }
    },
    // 输入框输入内容触发
    querySearch (queryString, callback) {
      const data = {
        dateBackgroundUrl: '/esComplexInfo/getCredentialListByKeyword',
        dateBackgroundRequestType: 'post',
        data: {
          credentialName: queryString
        }
      }
      let list = []
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          res.forEach((item, index) => {
            if (index < 5) {
              const obj = {
                value: item.qualificationName,
                id: item.relevancyId
              }
              arr.push(obj)
            }
          })
          list = arr
          callback(list)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 点击查询触发
    handleSearchList: function () {
      const vm = this
      // 如果跟上次查询的不一样就清理下面
      if (vm.oldKeyword !== vm.conditions.keyword) {
        vm.closeAllTag()
        vm.queryConditions.provinceModel = {}
        vm.queryConditions.cityModel = {}
        vm.queryConditions.moneyModel = {}
        vm.queryConditions.relevancyIdArr = []
        vm.queryConditions.multiSelect = null
        vm.queryConditions.radio = null
        vm.queryConditions.credentialType = ''
      }
      vm.oldKeyword = vm.conditions.keyword
      vm.queryConditions.keyword = vm.conditions.keyword
      vm.page.pageNum = 1
      // 去查询
      vm.getList()
    },
    queryList: function () {
      const vm = this
      if (vm.idToken) {
        if (vm.returnLimit) { // 已经返回受限了 就不继续查询了
          vm.page.pageNum = -Math.ceil(Math.random() * 1000)
          vm.fuzzyDataShow = true
        } else {
          vm.page.pageNum = 1
          vm.queryConditions.provinceModel = vm.conditions.provinceModel
          vm.queryConditions.cityModel = vm.conditions.cityModel
          vm.queryConditions.radio = vm.conditions.radio
          vm.queryConditions.credentialType = vm.conditions.credentialType
          vm.getList()
        }
      } else {
        vm.loginEmit()
      }
    },
    // 选则排序触发
    handleSelsect: function (e) {
      const vm = this
      if (vm.idToken) {
        if (vm.returnLimit) { // 已经返回受限了 就不继续查询了
          vm.page.pageNum = -Math.ceil(Math.random() * 1000)
          vm.fuzzyDataShow = true
        } else {
          vm.page.pageNum = 1
          vm.queryConditions.orderType = vm.options[e].orderType
          vm.getList()
        }
      } else {
        vm.value = '0'
        vm.loginEmit()
      }
    },
    getList: function () {
      const vm = this
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const data = {
        dateBackgroundUrl: vm.idToken ? '/esCredential/getESCredentialListByModel' : '/esFake/esCredential/getESCredentialListByModel',
        dateBackgroundRequestType: 'GET',
        data: {
          province: vm.queryConditions.provinceModel.name ? vm.queryConditions.provinceModel.name : null,
          city: vm.queryConditions.cityModel.name ? vm.queryConditions.cityModel.name : null,
          credentialName: vm.queryConditions.keyword ? vm.queryConditions.keyword.trim() : null,
          credentialAllConform: vm.queryConditions.radio ? vm.queryConditions.radio : null,
          credentialType: vm.queryConditions.credentialType ? vm.queryConditions.credentialType : null,
          pageNum: vm.page.pageNum,
          pageSize: 10,
          orderType: vm.queryConditions.orderType === '' ? null : vm.queryConditions.orderType,
          orderField: vm.queryConditions.orderType === '' ? null : 'issue_date'
        }
      }
      const forward = vm.idToken ? oldForwardBG : forwardBG
      forward(data).then(result => {
        if (result.code === 0 || result.code === 97 || result.code === 98) {
          if (result.code === 97) {
            vm.returnLimit = true
            vm.fuzzyDataShow = false
          } else if (result.code === 98) {
            vm.returnLimit = true
            vm.fuzzyDataShow = true
          } else {
            vm.returnLimit = false
            vm.fuzzyDataShow = false
          }
          const res = result.data
          const arr = []
          const companyIds = []
          if (res.length > 0) {
            res.forEach((item, index) => {
              vm.page.total = item.dataTotal
              const obj = {
                id: item.id,
                companyId: item.companyId,
                companyName: item.companyName,
                issuedBy: item.issuedBy === null || item.issuedBy === 'null' ? '-' : item.issuedBy,
                closingDate: item.closingDate === null || item.closingDate === 'null' || item.closingDate === '' ? '-' : (item.closingDate).substring(0, 10),
                issueDate: item.issueDate === null || item.issueDate === 'null' || item.issueDate === '' ? '-' : (item.issueDate).substring(0, 10),
                subQualificationName: item.subQualificationName === null || item.subQualificationName === 'null' ? '-' : item.subQualificationName,
                credentialNumber: item.credentialNumber === null || item.credentialNumber === 'null' || item.credentialNumber === '' ? '-' : item.credentialNumber,
                category: item.category === null || item.category === 'null' || item.category === '' ? '' : item.category
              }
              companyIds.push(item.id)
              arr.push(obj)
            })
            if (companyIds.length > 0) {
              vm.getListOtherData(companyIds)
            }
          } else {
            vm.page.total = 0
          }
          vm.list = arr
          loading.close()
        } else {
          loading.close()
        }
      }).catch(error => {
        loading.close()
        console.log(error)
      })
    },
    getListOtherData (companyIds) {
      const vm = this
      const data = {
        dateBackgroundUrl: '/tCompanyCredentialController/getCompanyCredentialByIds',
        dateBackgroundRequestType: 'get',
        data: {
          ids: companyIds.toString()
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const data = result.data ? result.data : []
          for (let j = 0; j < vm.list.length; j++) {
            for (let i = 0; i < data.length; i++) {
              if (vm.list[j].id === data[i].id) {
                vm.list[j].recordGrade = data[i].recordGrade
              }
            }
          }
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 输入框选择之后返回的数据
    handleSelect (item) {
      this.conditions.keyword = item.value
    },
    treeToName (arr) {
      const vm = this
      const result = []
      arr.forEach((item, index) => {
        const obj = []
        const m = item.length - 2
        const n = item.length - 1
        obj.push(item[m])
        for (let j = 0; j < vm.totalCascaderList.length; j++) {
          if (item[n] === vm.totalCascaderList[j].id) {
            obj.push(vm.totalCascaderList[j].name)
            const objNum = obj.join('#')
            result.push(objNum)
            break
          }
        }
      })
      return result.join(',')
    },
    treeToLabel (data, arr) {
      const result = []
      const childrenTreeToLabel = (data, arr, result) => {
        for (let j = 0; j < data.length; j++) {
          if (arr[0] === data[j].value) {
            result.push(data[j].label)
            if (data[j].children && arr.length > 1) {
              childrenTreeToLabel(data[j].children, arr.slice(1), result)
            }
            break
          }
        }
      }
      childrenTreeToLabel(data, arr, result)
      return result.join('>')
    },
    companyNameClick (companyId) {
      const vm = this
      vm.$store.dispatch('setBusId', companyId)
      vm.$router.push({ name: 'enterprise-details' })
    }
  },
  beforeumount () {
    const vm = this
    if (vm.vipLevelTime) {
      clearInterval(vm.vipLevelTime)
      vm.vipLevelTime = ''
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.input-search {
  margin-top: 120px;
}
/deep/ .el-input-group__append, .el-input-group__prepend {
  background-color: #5D6FE9;
  border: none;
  color: #fff;
  width: 70px;
}
.conditions-font-title {
  font-size: 14px;
  color: #999;
  display: block;
  height: 40px;
  line-height: 40px;
}
.focus-enterprises-tag {
  position: absolute;
  top: 50%;
  right: 20px;
  height: 30px;
  margin-top: -17px;
  border: 1px solid #5D6FE9;
  color: #5D6FE9;
  line-height: 30px;
  padding: 0 5px;
  font-size: 14px;
  cursor: pointer;
}
.focus-enterprises-tag-gray {
  position: absolute;
  top: 50%;
  right: 20px;
  height: 30px;
  margin-top: -17px;
  border: 1px solid #E6E6E6;
  color: #666;
  line-height: 30px;
  padding: 0 5px;
  font-size: 14px;
  cursor: pointer;
}
.focus-enterprises-tag-yellow {
  color: #EA9920;
  padding: 0 5px;
  border: 1px solid #EA9920;
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
}
.el-divider--horizontal {
  margin: 0;
}
.list-hover:hover{
  background-color: #F3F9FD;
}
/deep/ .el-input.el-input--medium.el-input--suffix {
  line-height: 50px !important;
}
</style>
